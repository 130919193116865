import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material";
import { Calculator } from "./components/Calculator";

declare module "@mui/material/styles" {
  interface Palette {
    lightButton: Palette["primary"];
    darkButton: Palette["primary"];
  }

  interface PaletteOptions {
    lightButton?: PaletteOptions["primary"];
    darkButton?: PaletteOptions["primary"];
  }
}

// Update the Button's color options to include an white option
declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    lightButton: true;
    darkButton: true;
  }
}

function App() {
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
      lightButton: {
        main: "#FFFFFF",
      },
      darkButton: {
        main: "#3b3b3b",
      },
    },
  });

  return (
    <ThemeProvider theme={darkTheme}>
      <Calculator />
    </ThemeProvider>
  );
}

export default App;
