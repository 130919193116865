import {
  Box,
  Button,
  Collapse,
  FormControl,
  InputLabel,
  Link,
  List,
  ListItem,
  MenuItem,
  Paper,
  Select,
  SvgIcon,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { NumericFormat, NumericFormatProps } from "react-number-format";
import { DaysInMonth, analytics } from "./constants";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  MonthlyOffsetResults,
  daysInThisMonth,
  monthlyOffsetCalcualtor,
  roundToCents,
} from "../utils/utils";
import { TransitionGroup } from "react-transition-group";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";

export const boxStyles = {
  display: "flex",
  gap: "32px",
  flexDirection: "column",
  padding: "32px",
  background: "#303540",
};

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  prefix?: string;
  suffix?: string;
}

function numberWithCommas(x: number, addPlus?: boolean) {
  if (isNaN(x)) {
    return " N/A ";
  }
  const abs = x < 0 ? x * -1 : x;
  const absWithCents = abs.toFixed(2);

  let amount = "$" + absWithCents.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  if (x < 0) {
    return " -" + amount;
  }
  return addPlus ? " +" + amount : " " + amount;
}

export const NumericFormatCustom = React.forwardRef<
  NumericFormatProps,
  CustomProps
>(function NumericFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
      prefix={props.prefix}
      suffix={props.suffix}
      style={{ caretColor: "white" }}
    />
  );
});

export interface OffsetEachDay {
  amount: number;
  day: number;
}

export interface interestRateEachDay {
  amount: number;
  day: number;
}

export interface CalculatorState {
  outstandingLoan: number;
  startingOffset: number;
  monthlyRepayment: number;
  interestRateEachDay: interestRateEachDay[];
  daysInMonth: DaysInMonth;
  offsetEachDay: OffsetEachDay[];
}

export type OnePropertyOnly<T> = {
  [K in keyof T]-?: { [P in K]: T[P] };
}[keyof T];

export const Calculator = () => {
  const [calculator, setCalculator] = useState<CalculatorState>({
    outstandingLoan: 500000,
    startingOffset: 20000,
    monthlyRepayment: 3072,
    interestRateEachDay: [{ day: 1, amount: 6.23 }],
    daysInMonth: daysInThisMonth() as DaysInMonth,
    offsetEachDay: [
      { day: 1, amount: 2000 },
      { day: 15, amount: 1500 },
    ],
  });
  const [calculatorResults, setCalculatorResults] =
    useState<MonthlyOffsetResults>();

  const handleCalculatorUpdate = (update: OnePropertyOnly<CalculatorState>) => {
    analytics.logEvent(Object.keys(update)[0]);
    setCalculator({
      ...calculator,
      ...update,
    });
  };

  useEffect(() => {
    analytics.logEvent("calculation_updated");
    analytics.logEvent(`calculator_results_${JSON.stringify(calculator)}`);
    setCalculatorResults(
      monthlyOffsetCalcualtor({
        ...calculator,
      })
    );
  }, [calculator]);

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        background: "#303540",
        justifyContent: "center",
      }}
    >
      <Box sx={{ maxWidth: "800px" }}>
        <Box sx={boxStyles}>
          <Box sx={{ display: "inline-flex", alignItems: "center" }}>
            <Typography
              fontSize={"x-large"}
              variant="body1"
              fontWeight={"bold"}
              sx={{
                color: (theme) =>
                  theme.palette.mode === "dark" ? "white" : "#303540",
                background: "#303540",
                textAlign: "center",
                paddingBottom: "12px",
                borderBottom: "1px solid",
                marginBottom: "12px",
              }}
            >
              Mortgage Interest Charge Calculator{" "}
              <SvgIcon sx={{ position: "relative", top: "4px" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.0"
                  width="512.000000pt"
                  height="512.000000pt"
                  viewBox="0 0 512.000000 512.000000"
                  preserveAspectRatio="xMidYMid meet"
                  fill="red"
                >
                  <g
                    transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                    fill="white"
                    stroke="none"
                  >
                    <path d="M1215 4591 c-140 -36 -217 -77 -311 -165 -89 -82 -153 -182 -189 -293 -43 -134 -35 -352 16 -445 5 -10 9 -22 9 -28 0 -18 81 -143 123 -188 97 -104 232 -178 377 -206 71 -13 161 -16 558 -16 284 0 472 -4 470 -9 -1 -5 -131 -139 -287 -298 -302 -306 -343 -353 -389 -448 -48 -99 -57 -147 -57 -300 0 -146 5 -171 56 -282 47 -101 139 -210 229 -270 51 -34 142 -77 183 -87 39 -9 42 -25 14 -87 -35 -79 -37 -86 -53 -183 -46 -285 113 -582 379 -707 139 -65 109 -62 822 -66 570 -4 659 -2 720 12 256 59 442 224 520 462 21 66 25 96 25 203 0 143 -9 179 -72 308 -67 134 -193 254 -328 312 -132 56 -174 59 -697 60 -266 0 -483 3 -483 8 0 4 133 142 295 307 339 344 378 395 426 555 21 68 19 312 -3 372 -50 136 -96 209 -189 299 -66 64 -191 137 -262 153 -38 9 -42 26 -19 77 60 135 77 245 58 374 -8 50 -19 106 -26 125 -79 230 -258 392 -497 451 -73 17 -120 19 -713 18 -576 0 -642 -2 -705 -18z m1348 -335 c76 -20 113 -41 162 -91 71 -73 90 -124 90 -235 0 -78 -4 -103 -23 -141 -28 -56 -123 -164 -163 -184 -24 -13 -122 -15 -642 -15 -386 0 -635 4 -673 11 -84 14 -135 41 -192 98 -181 180 -94 487 156 556 72 20 1209 21 1285 1z m467 -1023 c136 -50 220 -170 220 -315 0 -72 -16 -129 -52 -181 -13 -19 -208 -222 -434 -451 l-411 -416 -109 0 c-118 0 -172 14 -234 59 -43 31 -50 38 -81 81 -77 105 -80 267 -7 373 13 19 207 221 433 451 l410 416 110 0 c77 0 123 -5 155 -17z m782 -1714 c173 -35 282 -165 281 -334 -1 -156 -104 -283 -260 -321 -83 -19 -1191 -20 -1273 0 -105 25 -179 82 -227 176 -25 49 -28 65 -28 150 0 113 16 154 97 245 93 104 25 95 735 95 405 0 641 -4 675 -11z" />
                  </g>
                </svg>
              </SvgIcon>
            </Typography>
          </Box>

          <Box display={{ display: "flex" }}>
            <Typography
              sx={{ marginTop: "-32px" }}
              color={"rgba(255, 255, 255, 0.7)"}
              variant="caption"
            >
              A calculator for the interest charge on your mortgage. Ensure your
              bank is getting it right.
            </Typography>
          </Box>
          <Paper elevation={3} sx={{ display: "flex", flexGrow: 1 }}>
            <TextField
              label={"Outstanding Loan"}
              value={calculator.outstandingLoan}
              onChange={(event) =>
                handleCalculatorUpdate({
                  outstandingLoan: parseFloat(
                    event.target.value.replace("$", "")
                  ),
                })
              }
              name="numberformat"
              InputProps={{
                inputComponent: NumericFormatCustom as any,
              }}
              inputProps={{
                prefix: "$ ",
              }}
              onBlur={(event) => {
                const newOutstandingLoad = parseFloat(
                  event.target.value.replace("$", "")
                );
                if (
                  event.target.value === "" ||
                  isNaN(newOutstandingLoad) ||
                  newOutstandingLoad < 0
                ) {
                  handleCalculatorUpdate({ outstandingLoan: 0 });
                }
              }}
              variant="outlined"
              sx={{
                display: "flex",
                flexGrow: 1,
                background: (theme: Theme) =>
                  theme.palette.mode === "dark" ? "#3a404d" : "white",
                input: { color: "white", fontSize: 20 },
              }}
            />
          </Paper>
          <Paper elevation={3} sx={{ display: "flex", flexGrow: 1 }}>
            <TextField
              label={"Offset Balance On First Day Of Month"}
              value={calculator.startingOffset}
              onChange={(event) =>
                handleCalculatorUpdate({
                  startingOffset: parseFloat(
                    event.target.value.replace("$", "")
                  ),
                })
              }
              name="numberformat"
              InputProps={{
                inputComponent: NumericFormatCustom as any,
              }}
              inputProps={{
                prefix: "$ ",
              }}
              onBlur={(event) => {
                const newStartingOffset = parseFloat(
                  event.target.value.replace("$", "")
                );
                if (
                  event.target.value === "" ||
                  isNaN(newStartingOffset) ||
                  newStartingOffset < 0
                ) {
                  handleCalculatorUpdate({ startingOffset: 0 });
                }
              }}
              variant="outlined"
              sx={{
                display: "flex",
                flexGrow: 1,
                background: (theme: Theme) =>
                  theme.palette.mode === "dark" ? "#3a404d" : "white",
                input: { color: "white", fontSize: 20 },
              }}
            />
          </Paper>
          <Paper elevation={3} sx={{ display: "flex", flexGrow: 1 }}>
            <TextField
              label={"Monthly Repayment At End Of Month"}
              value={calculator.monthlyRepayment}
              onChange={(event) =>
                handleCalculatorUpdate({
                  monthlyRepayment: parseFloat(
                    event.target.value.replace("$", "")
                  ),
                })
              }
              name="numberformat"
              InputProps={{
                inputComponent: NumericFormatCustom as any,
              }}
              inputProps={{
                prefix: "$ ",
              }}
              onBlur={(event) => {
                const newMonthlyRepayment = parseFloat(
                  event.target.value.replace("$", "")
                );
                if (
                  event.target.value === "" ||
                  isNaN(newMonthlyRepayment) ||
                  newMonthlyRepayment < 0
                ) {
                  handleCalculatorUpdate({ monthlyRepayment: 0 });
                }
              }}
              variant="outlined"
              sx={{
                display: "flex",
                flexGrow: 1,
                background: (theme: Theme) =>
                  theme.palette.mode === "dark" ? "#3a404d" : "white",
                input: { color: "white", fontSize: 20 },
              }}
            />
          </Paper>
          <Paper
            elevation={3}
            sx={{ display: "flex", flexGrow: 1, marginBottom: "-24px" }}
          >
            <FormControl sx={{ minWidth: "100%", background: "#3a404d" }}>
              <InputLabel id="daysInMonth">Days In Month</InputLabel>
              <Select
                labelId="daysInMonth"
                value={calculator.daysInMonth}
                label="daysInMonth"
                onChange={(event) => {
                  const removeOutOfRangeOffsetDays =
                    calculator.offsetEachDay.filter(
                      (offset) =>
                        offset.day < parseInt(event.target.value.toString())
                    );

                  handleCalculatorUpdate({
                    daysInMonth: event.target.value as DaysInMonth,
                    offsetEachDay: removeOutOfRangeOffsetDays,
                  });
                }}
                sx={{ color: "white", fontSize: 20 }}
              >
                {[28, 29, 30, 31].map((day) => (
                  <MenuItem
                    key={day}
                    value={day}
                    sx={{ color: "white", fontSize: 20 }}
                  >
                    {day}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Paper>
          <TransitionGroup>
            {calculator.interestRateEachDay.map((interestRate) => {
              return (
                <Collapse key={interestRate.day}>
                  <Box>
                    <Box
                      sx={{ display: "flex", gap: "16px", marginTop: "28px" }}
                    >
                      <Paper
                        elevation={3}
                        sx={{ display: "flex", flexGrow: 1 }}
                      >
                        <TextField
                          label={"Interest Rate"}
                          value={interestRate.amount}
                          onChange={(event) => {
                            // remove day
                            const RemoveDay =
                              calculator.interestRateEachDay.filter(
                                (interest) => interest.day !== interestRate.day
                              );

                            // add day
                            const AddDay = [
                              ...RemoveDay,
                              {
                                day: interestRate.day,
                                amount: parseFloat(
                                  event.target.value.replace("$", "")
                                ),
                              },
                            ];

                            handleCalculatorUpdate({
                              // sort by day
                              interestRateEachDay: AddDay.sort(
                                (a, b) => a.day - b.day
                              ),
                            });
                          }}
                          name="numberformat"
                          InputProps={{
                            inputComponent: NumericFormatCustom as any,
                          }}
                          inputProps={{
                            suffix: "%",
                          }}
                          onBlur={(event) => {
                            if (
                              event.target.value === "" ||
                              isNaN(parseFloat(event.target.value))
                            ) {
                              // remove day
                              const RemoveDay =
                                calculator.interestRateEachDay.filter(
                                  (interest) =>
                                    interest.day !== interestRate.day
                                );

                              // add day
                              const AddDay = [
                                ...RemoveDay,
                                {
                                  day: interestRate.day,
                                  amount: 0,
                                },
                              ];

                              handleCalculatorUpdate({
                                // sort by day
                                interestRateEachDay: AddDay.sort(
                                  (a, b) => a.day - b.day
                                ),
                              });
                            }
                          }}
                          variant="outlined"
                          sx={{
                            display: "flex",
                            flexGrow: 1,
                            background: (theme: Theme) =>
                              theme.palette.mode === "dark"
                                ? "#3a404d"
                                : "white",
                            input: { color: "white", fontSize: 20 },
                          }}
                        />
                      </Paper>
                      <Paper
                        elevation={3}
                        sx={{ display: "flex", flexGrow: 1 }}
                      >
                        <FormControl
                          sx={{
                            width: "100%",
                            minWidth: "80px",
                            background: "#3a404d",
                          }}
                        >
                          <InputLabel id="interestOnDay">From Day</InputLabel>
                          <Select
                            labelId="interestOnDay"
                            value={interestRate.day}
                            label="interestOnDay"
                            sx={{
                              color: "white",
                              fontSize: 20,
                            }}
                            disabled={interestRate.day === 1}
                            onChange={(event) => {
                              // remove day
                              const RemoveDay =
                                calculator.interestRateEachDay.filter(
                                  (interest) =>
                                    interest.day !== interestRate.day
                                );

                              // add day
                              const AddDay = [
                                ...RemoveDay,
                                {
                                  day: parseInt(event.target.value.toString()),
                                  amount: interestRate.amount,
                                },
                              ];

                              const sortedInterestRateEachDay = AddDay.sort(
                                (a, b) => a.day - b.day
                              );

                              handleCalculatorUpdate({
                                interestRateEachDay: sortedInterestRateEachDay,
                              });
                            }}
                          >
                            {Array.from(
                              Array(calculator.daysInMonth).keys()
                            ).map((day) => (
                              <MenuItem
                                value={day + 1}
                                disabled={calculator.interestRateEachDay
                                  .map((interest) => interest.day)
                                  .includes(day + 1)}
                                key={day}
                                sx={{ color: "white", fontSize: 20 }}
                              >
                                {day + 1}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Paper>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <CancelIcon
                          sx={{
                            color: "white",
                            opacity: interestRate.day === 1 ? 0.3 : 1,
                            fontSize: "32px",
                          }}
                          onClick={() => {
                            if (interestRate.day !== 1) {
                              handleCalculatorUpdate({
                                interestRateEachDay:
                                  calculator.interestRateEachDay.filter(
                                    (interest) =>
                                      interest.day !== interestRate.day
                                  ),
                              });
                            }
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Collapse>
              );
            })}
          </TransitionGroup>
          <Paper
            elevation={3}
            sx={{
              display: "flex",
              flexGrow: 1,
              backgroundColor: "#303540",
              marginTop: "-8px",
              marginBottom: "-16px",
            }}
          >
            <Button
              fullWidth
              sx={{
                borderColor: "rgba(255, 255, 255, 0.23)",
                color: "white",
                height: "56px",
              }}
              variant="outlined"
              size="large"
              onClick={() => {
                // last day
                const lastDay =
                  calculator.interestRateEachDay.length > 0
                    ? calculator.interestRateEachDay[
                        calculator.interestRateEachDay.length - 1
                      ]
                    : { day: 0, amount: 0 };

                handleCalculatorUpdate({
                  interestRateEachDay: [
                    ...calculator.interestRateEachDay,
                    {
                      day: lastDay.day + 1,
                      amount: lastDay.amount,
                    },
                  ],
                });
              }}
            >
              Interest Rate Change
              <AddIcon sx={{ color: "white" }} />
            </Button>
          </Paper>

          <TransitionGroup>
            {calculator.offsetEachDay.map((offsetTopUp) => {
              return (
                <Collapse key={offsetTopUp.day}>
                  <Box>
                    <Box
                      sx={{ display: "flex", gap: "16px", marginTop: "24px" }}
                    >
                      <Paper
                        elevation={3}
                        sx={{ display: "flex", flexGrow: 1 }}
                      >
                        <TextField
                          label={"Offset Addition"}
                          value={offsetTopUp.amount}
                          onChange={(event) => {
                            // remove day
                            const RemoveDay = calculator.offsetEachDay.filter(
                              (offset) => offset.day !== offsetTopUp.day
                            );

                            // add day
                            const AddDay = [
                              ...RemoveDay,
                              {
                                day: offsetTopUp.day,
                                amount: parseFloat(
                                  event.target.value.replace("$", "")
                                ),
                              },
                            ];

                            handleCalculatorUpdate({
                              // sort by day
                              offsetEachDay: AddDay.sort(
                                (a, b) => a.day - b.day
                              ),
                            });
                          }}
                          name="numberformat"
                          InputProps={{
                            inputComponent: NumericFormatCustom as any,
                          }}
                          inputProps={{
                            prefix: "$ ",
                          }}
                          onBlur={(event) => {
                            let newOffsetAmount;
                            if (event.target.value.includes("-")) {
                              newOffsetAmount =
                                -1 *
                                parseFloat(
                                  event.target.value.replace("-$", "")
                                );
                            } else {
                              newOffsetAmount = parseFloat(
                                event.target.value.replace("$", "")
                              );
                            }
                            if (
                              event.target.value === "" ||
                              isNaN(newOffsetAmount)
                            ) {
                              // remove day
                              const RemoveDay = calculator.offsetEachDay.filter(
                                (offset) => offset.day !== offsetTopUp.day
                              );

                              // add day
                              const AddDay = [
                                ...RemoveDay,
                                {
                                  day: offsetTopUp.day,
                                  amount: 0,
                                },
                              ];

                              handleCalculatorUpdate({
                                // sort by day
                                offsetEachDay: AddDay.sort(
                                  (a, b) => a.day - b.day
                                ),
                              });
                            }
                          }}
                          variant="outlined"
                          sx={{
                            display: "flex",
                            flexGrow: 1,
                            background: (theme: Theme) =>
                              theme.palette.mode === "dark"
                                ? "#3a404d"
                                : "white",
                            input: { color: "white", fontSize: 20 },
                          }}
                        />
                      </Paper>
                      <Paper
                        elevation={3}
                        sx={{ display: "flex", flexGrow: 1 }}
                      >
                        <FormControl
                          sx={{
                            width: "100%",
                            minWidth: "80px",
                            background: "#3a404d",
                          }}
                        >
                          <InputLabel id="offsetOnDay">On Day</InputLabel>
                          <Select
                            labelId="offsetOnDay"
                            value={offsetTopUp.day}
                            label="offsetOnDay"
                            sx={{
                              color: "white",
                              fontSize: 20,
                            }}
                            onChange={(event) => {
                              // remove day
                              const RemoveDay = calculator.offsetEachDay.filter(
                                (offset) => offset.day !== offsetTopUp.day
                              );

                              // add day
                              const AddDay = [
                                ...RemoveDay,
                                {
                                  day: parseInt(event.target.value.toString()),
                                  amount: offsetTopUp.amount,
                                },
                              ];

                              const sortedOffsetEachDay = AddDay.sort(
                                (a, b) => a.day - b.day
                              );

                              handleCalculatorUpdate({
                                offsetEachDay: sortedOffsetEachDay,
                              });
                            }}
                          >
                            {Array.from(
                              Array(calculator.daysInMonth).keys()
                            ).map((day) => (
                              <MenuItem
                                value={day + 1}
                                disabled={calculator.offsetEachDay
                                  .map((offset) => offset.day)
                                  .includes(day + 1)}
                                key={day}
                                sx={{ color: "white", fontSize: 20 }}
                              >
                                {day + 1}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Paper>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <CancelIcon
                          sx={{ color: "white", fontSize: "32px" }}
                          onClick={() => {
                            handleCalculatorUpdate({
                              offsetEachDay: calculator.offsetEachDay.filter(
                                (offset) => offset.day !== offsetTopUp.day
                              ),
                            });
                          }}
                        />
                      </Box>
                    </Box>
                    <Typography
                      color={"rgba(255, 255, 255, 0.7)"}
                      sx={{
                        paddingLeft: "16px",
                        paddingTop: "4px",
                      }}
                    >
                      {"Offset Balance: " +
                        numberWithCommas(
                          calculator.startingOffset +
                            calculator.offsetEachDay.reduce(
                              (acc, offset) =>
                                offset.day <= offsetTopUp.day
                                  ? isNaN(offset.amount)
                                    ? acc
                                    : offset.amount + acc
                                  : acc,
                              0
                            )
                        )}
                    </Typography>
                  </Box>
                </Collapse>
              );
            })}
          </TransitionGroup>
          <Paper
            elevation={3}
            sx={{
              display: "flex",
              flexGrow: 1,
              backgroundColor: "#303540",
              marginTop: "-8px",
            }}
          >
            <Button
              fullWidth
              sx={{
                borderColor: "rgba(255, 255, 255, 0.23)",
                color: "white",
                height: "56px",
              }}
              variant="outlined"
              size="large"
              onClick={() => {
                // last day
                const lastDay =
                  calculator.offsetEachDay.length > 0
                    ? calculator.offsetEachDay[
                        calculator.offsetEachDay.length - 1
                      ]
                    : { day: 0, amount: 0 };

                handleCalculatorUpdate({
                  offsetEachDay: [
                    ...calculator.offsetEachDay,
                    {
                      day: lastDay.day + 1,
                      amount: lastDay.amount,
                    },
                  ],
                });
              }}
            >
              Offset Contributions
              <AddIcon sx={{ color: "white" }} />
            </Button>
          </Paper>
          <Paper elevation={3} sx={{ background: "#3a404d", padding: "16px" }}>
            <Typography
              fontSize={"x-large"}
              variant="body1"
              fontWeight={"bold"}
              sx={{
                paddingBottom: "12px",
                borderBottom: "2px solid",
                marginBottom: "12px",
              }}
            >
              End Of Month Summary
            </Typography>
            <Typography
              sx={{ display: "flex", justifyContent: "space-between" }}
              fontSize={"larger"}
              variant="body1"
              marginBottom={"5px"}
            >
              Interest Charge:
              {numberWithCommas(
                roundToCents(
                  calculatorResults?.monthlyInterestWithOffset as number
                )
              )}
            </Typography>
            <Typography
              sx={{
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: "12px",
                borderBottom: "1px solid",
                marginBottom: "12px",
              }}
              fontSize={"larger"}
              variant="body1"
              marginBottom={"5px"}
            >
              Principal Repayment:
              {numberWithCommas(
                roundToCents(
                  calculatorResults?.monthlyPrincipalWithOffset as number
                )
              )}
            </Typography>
            <Typography
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
              fontSize={"larger"}
              variant="body1"
              marginBottom={"5px"}
            >
              Interest Savings:
              {numberWithCommas(
                roundToCents(calculatorResults?.OffsetSavings as number)
              )}
            </Typography>
            <Typography
              sx={{
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: "12px",
                borderBottom: "1px solid",
                marginBottom: "12px",
              }}
              fontSize={"larger"}
              variant="body1"
              marginBottom={"5px"}
            >
              Contributions Savings:
              {numberWithCommas(
                roundToCents(calculatorResults?.InterestChange as number),
                true
              )}
            </Typography>
            <Typography
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
              fontSize={"larger"}
              variant="body1"
              marginBottom={"5px"}
            >
              Total Contributions:
              {numberWithCommas(
                roundToCents(
                  calculator.offsetEachDay.reduce(
                    (acc, offset) => offset.amount + acc,
                    0
                  )
                )
              )}
            </Typography>
            <Typography
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
              fontSize={"larger"}
              variant="body1"
              marginBottom={"5px"}
            >
              Mortgage:
              {numberWithCommas(roundToCents(-1 * calculator.monthlyRepayment))}
            </Typography>
            <Typography
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
              fontSize={"larger"}
              variant="body1"
              marginBottom={"5px"}
            >
              Offset Change:
              {numberWithCommas(
                roundToCents(
                  calculator.offsetEachDay.reduce(
                    (acc, offset) => offset.amount + acc,
                    0
                  ) - calculator.monthlyRepayment
                ),
                true
              )}
            </Typography>
            <Typography
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
              fontSize={"larger"}
              variant="body1"
              marginBottom={"5px"}
            >
              Offset Balance:
              {numberWithCommas(
                calculator.startingOffset +
                  roundToCents(
                    calculator.offsetEachDay.reduce(
                      (acc, offset) => offset.amount + acc,
                      0
                    )
                  ) -
                  calculator.monthlyRepayment
              )}
            </Typography>
            <Typography
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
              fontSize={"larger"}
              variant="body1"
              marginBottom={"5px"}
            >
              Remaining Loan:
              {numberWithCommas(
                calculator.outstandingLoan -
                  (calculatorResults?.monthlyPrincipalWithOffset ?? 0) >
                  0
                  ? calculator.outstandingLoan -
                      (calculatorResults?.monthlyPrincipalWithOffset ?? 0)
                  : 0
              )}
            </Typography>
          </Paper>
          <Paper
            sx={{
              borderWidth: "0px",
              width: "fit-content",
              padding: "10px",
              borderRadius: "5px",
              margin: "auto",
              textDecorationColor: "transparent",
              backgroundImage:
                "linear-gradient(90deg, rgb(239, 255, 0) 0%, rgb(226, 23, 230) 100%)",
            }}
            elevation={3}
          >
            <Link
              sx={{
                color: "#303540",
                textDecorationColor: "transparent",
              }}
              onClick={() => analytics.logEvent("donate_coffee_click")}
              href="https://ko-fi.com/interestchargecalculator"
              target="_blank"
              rel="noreferrer"
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
                <AutoAwesomeIcon />
                <Typography sx={{ fontWeight: "Bold" }} variant="h6">
                  Buy Me A Coffee
                </Typography>
                <OpenInNewIcon />
              </Box>
            </Link>
          </Paper>
          <Box display={{ display: "flex" }}>
            <List sx={{ marginTop: "-32px" }}>
              <ListItem sx={{ paddingLeft: 0, paddingRight: 0 }}>
                <Typography
                  color={"rgba(255, 255, 255, 0.7)"}
                  variant="caption"
                >
                  Assumptions:
                </Typography>
              </ListItem>
              <ListItem sx={{ paddingLeft: 0, paddingRight: 0 }}>
                <Typography
                  color={"rgba(255, 255, 255, 0.7)"}
                  variant="caption"
                  paddingLeft={0}
                  paddingRight={0}
                >
                  1. Your mortgage is deducted from your offset balance at
                  midnight on the last day of the month.
                </Typography>
              </ListItem>
              <ListItem sx={{ paddingLeft: 0, paddingRight: 0 }}>
                <Typography
                  color={"rgba(255, 255, 255, 0.7)"}
                  variant="caption"
                  paddingLeft={0}
                  paddingRight={0}
                >
                  2. Interest is charged on the difference between your
                  outstanding loan and offset balance at midnight for the
                  preceding day. So an offset contribution made at any time on
                  day 1 will be included in the day 1 interest calculation.
                </Typography>
              </ListItem>
            </List>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
