// For the interface:
// You can do something similiar to the career switch calculator for the income blocks
// the offset is assumed to be the full number of days unless a user
// "Increase offset" after "day"

import { interestRateEachDay, OffsetEachDay } from "../components/Calculator";

// I'm assuming the is an array with days being consecutive integers of day
// 1 up to month days e.g: [1,2,3..., 31] --> starting index at 1, it's just easier with dates
// interface OffsetEachDay {
//   amount: number;
//   day: number;
// }

// in case it's requested in a reversion to use local storage
// here's the test key value you'll need to get to work
// key: calculator value: {"outstandingLoan":500000,"startingOffset":20000,"monthlyRepayment":3072,"interestRate":6.23,"daysInMonth":31,"offsetEachDay":[{"day":15,"amount":2000}]}

interface MonthlyOffsetCalcualtor {
  outstandingLoan: number;
  startingOffset: number;
  monthlyRepayment: number;
  interestRateEachDay: interestRateEachDay[];
  daysInMonth: number;
  offsetEachDay: OffsetEachDay[];
}

export interface MonthlyOffsetResults {
  monthlyInterestWithOffset: number;
  monthlyPrincipalWithOffset: number;
  OffsetSavings: number;
  InterestChange: number;
}

const mapOffsetEachDay = (
  startingOffset: number,
  daysInMonth: number,
  offsetEachDay: OffsetEachDay[]
): OffsetEachDay[] => {
  let increase: number = 0;
  return Array.from(Array(daysInMonth).keys()).map((day) => {
    const amount = offsetEachDay.find(
      (offset) => offset.day === day + 1
    )?.amount;
    if (amount) {
      increase += amount;
    }
    return { day: day + 1, amount: startingOffset + increase };
  });
};

const mapInterestRateEachDay = (
  daysInMonth: number,
  interestRateEachDay: interestRateEachDay[]
): interestRateEachDay[] => {
  let interestRate: number = 0;
  return Array.from(Array(daysInMonth).keys()).map((day) => {
    const getInterestRate = interestRateEachDay.find(
      (interest) => interest.day === day + 1
    )?.amount;
    interestRate =
      getInterestRate === undefined ? interestRate : getInterestRate;
    return { day: day + 1, amount: interestRate / 365 / 100 };
  });
};

// or do it now:
export function monthlyOffsetCalcualtor({
  outstandingLoan,
  startingOffset,
  monthlyRepayment,
  interestRateEachDay,
  daysInMonth,
  offsetEachDay,
}: MonthlyOffsetCalcualtor): MonthlyOffsetResults {
  // convert offset inputs to daily array
  const offsetEachDayArray = mapOffsetEachDay(
    startingOffset,
    daysInMonth,
    offsetEachDay
  );

  // convert interest inputs to daily array
  const interestRateEachDayArray = mapInterestRateEachDay(
    daysInMonth,
    interestRateEachDay
  );

  // store the daily outstanding loan amounts:
  const dailyLoanAmounts = offsetEachDayArray.map((dailyOffset) => {
    const effectiveLoan = outstandingLoan - dailyOffset.amount;
    return effectiveLoan > 0 ? effectiveLoan : 0;
  });

  // calculate the interest per day with the daily offset amounts included
  const interestPerDayWithOffset = dailyLoanAmounts.map(
    (amount, index) => amount * interestRateEachDayArray[index].amount
  );

  // calculate the montly interest with offset included
  const monthlyInterestWithOffset = interestPerDayWithOffset.reduce(
    (a, b) => a + b,
    0
  );

  // Calculate the monthly principle with offset
  const monthlyPrincipalWithOffset =
    monthlyRepayment - monthlyInterestWithOffset;

  // determine your interest repayment:
  const monthlyInterestWithStartingOffset = interestRateEachDayArray.reduce(
    (acc, interestRateEachDay) =>
      acc + (outstandingLoan - startingOffset) * interestRateEachDay.amount,
    0
  );

  // Monthly savings
  const InterestChange =
    monthlyInterestWithStartingOffset - monthlyInterestWithOffset;

  // determine your interest repayment:
  const monthlyInterestNoOffset = interestRateEachDayArray.reduce(
    (acc, interestRateEachDay) =>
      acc + outstandingLoan * interestRateEachDay.amount,
    0
  );

  // calculate the total saving each day by having the money in the offset which is
  const OffsetSavings = monthlyInterestNoOffset - monthlyInterestWithOffset;

  return {
    monthlyInterestWithOffset,
    monthlyPrincipalWithOffset,
    OffsetSavings,
    InterestChange,
  };
}

export function roundToCents(x: number) {
  return Math.round((x + Number.EPSILON) * 100) / 100;
}

export function daysInThisMonth() {
  var now = new Date();
  return new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
}
