// export const defaultOffsetEachDay = { 10: 1000, 18: 500 };

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/analytics";

export type DaysInMonth = 28 | 29 | 30 | 31;

export type DayInMonth =
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18
  | 19
  | 20
  | 21
  | 22
  | 23
  | 24
  | 25
  | 26
  | 27
  | 28
  | 29
  | 30
  | 31;

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC1yMXRvaxIw1p5P3KmdJh9f5L-ydhlQcQ",
  authDomain: "interestchargecheck.firebaseapp.com",
  projectId: "interestchargecheck",
  storageBucket: "interestchargecheck.appspot.com",
  messagingSenderId: "427119781937",
  appId: "1:427119781937:web:2c556494ab241d13af0d10",
  measurementId: "G-7GTBVJJKNT",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// setup analytics
export const analytics = firebase.analytics();
